import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import { configureHeader, setListViewType } from "@actions/ui";
import { Helmet } from "react-helmet";

import Footer from "@components/global/footer/footer";
import BrandTile from "@components/global/tiles/brand-tile";
import EventTile from "@components/global/tiles/EventTile";
import { updateFilter } from "@actions/update-filter";
import OptionSelect from "@components/form/option-select";

import { CURATOR_TILE, STANDARD_TILE } from "@constants/content-tile-types";
import { EVENT_STATE_PUBLISHED } from "@constants/config";

import { getAllCategoryFilters } from "@utils/filter-utils";
import { getActiveLocations, currentLocation } from "@selectors/filters";
import { stripHTML } from "@utils/strings";
import { META } from "@constants/config";
import { getBrand } from "@utils/http_service/events";
import SadFace from "@assets/image/sad-face.png";
import _get from "lodash/get"
import { getEvents } from "../utils/http_service/events";

class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: props.serverData.events
    };
  }
  componentDidMount = () => {
    if (!this.props.serverData.ttfBrand?.displayName) {
      navigate("/events")
    }
    window && window.scrollTo(0, 0);
    this.props.configureHeader({
      headerVisibilityLocked: true,
      headerTheme: "white",
    });

    this.setViewType("grid");
  };

  componentDidUpdate = async (prevProps) => {
    const currLocation = _get(this.props.currentRegion, 'value', null)
    const prevLocation = _get(prevProps.currentRegion, 'value', null)
    if (currLocation !== prevLocation && currLocation && prevLocation) {
      const events = await getEvents(this.props.serverData.ttfBrand.slug)
      this.setState({ events })
    }
  }

  setViewType = (type) => {
    this.props.setListViewType(type);
  };

  getHostedEvents = () => {
    const {
      currentRegion,
      serverData: { ttfBrand },
    } = this.props;
    const { events } = this.state
    return events
      .filter((event) => {
        return (
          event.event_state === EVENT_STATE_PUBLISHED &&
          event.brand_slug === ttfBrand.slug &&
          event.region === currentRegion.value
        );
      })
      .map((event, i) => {
        return (
          <EventTile
            key={String(event.id + i)}
            type={STANDARD_TILE}
            column="col s12 m6 xl4"
            categoryFilters={getAllCategoryFilters(this.props.filters)}
            {...event}
          />
        );
      });
  };

  onLocationChange = (e) => {
    this.props.updateFilter({
      type: "region",
      value: e.value,
      selected: true,
    });
  };

  getCurrentRegion = () => {
    const { currentRegion } = this.props;
    if (currentRegion) return currentRegion.value;
    return "select";
  };

  render() {
    const {
      serverData: { ttfBrand },
    } = this.props;

    const { bio, shortBio, displayName, logo, slug } = ttfBrand;

    const hostedEvents = this.getHostedEvents();
    const metaDesciption = stripHTML(bio || shortBio);

    return (
      <div className="user-detail">
        <Helmet>
          <title>{`${META.title} | Curator | ${displayName}`}</title>
          <link
            rel="canonical"
            href={`${process.env.SERVER_HOST}/${encodeURI(slug)}`}
          />
          <meta name="description" content={metaDesciption} />
          <meta name="og:description" content={metaDesciption} />
          <meta
            property="og:title"
            content={`${META.title} | Curator | ${displayName}`}
          />
          {logo && <meta property="og:image" content={logo} />}
        </Helmet>

        <div className="container container__content">
          <div className="row">
            <div className="col s12 m3 col--curator">
              <div className="row">
                <BrandTile
                  key="brand-detail-info"
                  type={CURATOR_TILE}
                  showLongBio={true}
                  column="col s12"
                  brand={ttfBrand}
                />
              </div>
            </div>
            <div className="col s12 m9 l8 xl9 col--recommended-events">
              {hostedEvents.length > 0 ? (
                <span>
                  <div className="row">
                    <div className="col s12 heading heading--secondary">
                      <h2 className="heading__headline heading__headline--grey-bg">
                        <span>Hosted Events</span>
                      </h2>
                    </div>
                  </div>
                  <div className="row">{hostedEvents}</div>
                </span>
              ) : (
                <div className="row">
                  <div className="col s12">
                    <div className="no-events__content">
                      <div className="no-events__graphic">
                        <img alt="" src={SadFace} />
                      </div>
                      <div className="no-events__headline">
                        {displayName && (
                          <span>
                            {`${displayName} ISN’T CURRENTLY HOSTING ANY EVENTS IN `}
                            <span>
                              <OptionSelect
                                editable={true}
                                required={false}
                                type="regionSelect"
                                name="region"
                                initialValue={this.getCurrentRegion()}
                                options={this.props.regions}
                                defaultOption={{
                                  value: "select",
                                  label: "Select",
                                }}
                                handleChange={this.onLocationChange}
                              />
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="no-events__discovery-cta">
                        {"Discover other events "}
                        <Link to="/events">here</Link>
                        {"."}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    filters: state.eventFilters,
    regions: getActiveLocations(state),
    currentRegion: currentLocation(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    updateFilter: (data) => {
      dispatch(updateFilter(data));
    },
    setListViewType: (type) => {
      dispatch(setListViewType(type));
    },
  };
};

const BrandRedux = connect(mapStateToProps, mapDispatchToProps)(Brand);

export default BrandRedux;

export async function getServerData(props) {
  try {
    const slug = props?.params?.slug;
    const [ttfBrand, events] = await Promise.all([getBrand(slug), getEvents(slug)]);
    return {
      props: {
        ttfBrand,
        events,
      },
    };
  } catch (error) {
    return {
      props: { error: true, message: error, ttfBrand: { displayName: "" }, events: [] },
    };
  }
}

export const Head = () => <></>;
